import { twJoin } from '@troon/tailwind-preset/merge';
import { Video } from '@troon/video';
import { VimeoVideo } from '@troon/video/vimeo';
import { YoutubeVideo } from '@troon/video/youtube';
import { Match, splitProps, Switch } from 'solid-js';
import type { ComponentProps, ParentProps } from 'solid-js';

type BaseProps = ParentProps<{ reverse?: boolean }>;

type VimeoProps = BaseProps & {
	type: 'vimeo';
	code: ComponentProps<typeof VimeoVideo>['code'];
} & Partial<ComponentProps<typeof VimeoVideo>>;

type YoutubeProps = BaseProps & {
	type: 'youtube';
	code: ComponentProps<typeof YoutubeVideo>['code'];
} & Partial<ComponentProps<typeof YoutubeVideo>>;

type SelfhostedProps = BaseProps & {
	type?: never;
	src: ComponentProps<typeof Video>['src'];
} & Partial<ComponentProps<typeof Video>>;

export function VideoSection(props: VimeoProps | YoutubeProps | SelfhostedProps) {
	const [, videoProps] = splitProps(props, ['reverse', 'children', 'type']);
	return (
		<div class="grid grid-cols-1 gap-x-16 gap-y-8 md:grid-cols-2 lg:gap-x-36">
			<div class={twJoin('flex flex-col justify-center gap-8', props.reverse && 'order-2')}>{props.children}</div>
			<div class={twJoin('flex flex-col justify-center gap-8', props.reverse && 'order-1')}>
				<Switch fallback={<Video dialog {...(videoProps as ComponentProps<typeof Video>)} />}>
					<Match when={props.type === 'vimeo' && (videoProps as ComponentProps<typeof VimeoVideo>)}>
						{(videoProps) => <VimeoVideo {...videoProps()} />}
					</Match>
					<Match when={props.type === 'youtube' && (videoProps as ComponentProps<typeof YoutubeVideo>)}>
						{(videoProps) => <YoutubeVideo {...videoProps()} />}
					</Match>
				</Switch>
			</div>
		</div>
	);
}
